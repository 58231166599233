<template>
    <login-layout>
        <h5 class="text-primary mb-4">Forgot Password</h5>
        <b-form @success="forgotPasswordClicked" @error="formError" :save-url="loginUrl"
                #default="{model, loading}">
            <label class="label">Email</label>
            <validated-input placeholder="Enter Email ID" type="email" class="text-secondary" v-model="model.email"
                             :disabled="loading" :rules="rules.email"/>
            <span v-if="errorMessage" v-html="errorMessage"></span>
            <span class="text-secondary">An email with your credentials will be sent to your registered mail.</span>
            <div class="text-center mt-4">
                <btn class="pl-5 pr-5" text="Submit" loading-text="Submitting..." size="sm"
                     :disabled="loading" :loading="loading"/>
            </div>
        </b-form>
    </login-layout>
</template>

<script>
import urls from '@/data/urls';
import { mapActions } from 'vuex';
import LoginLayout from './LoginPageLayout';

export default {
    name: 'ForgotPassword',
    components: { LoginLayout },
    data () {
        return {
            loginUrl: urls.auth.forgotPassword,
            errorMessage: '',
            rules: {
                email: {
                    required: true
                }
            }
        };
    },
    methods: {
        ...mapActions(['setUser']),
        forgotPasswordClicked (response) {
            // if (response.data.error === false) {
            //     const redirectUrl = sessionStorage.getItem('redirectPath');
            //     this.setUser(response.data.user);
            //     this.$router.push(redirectUrl || { path: '/' });
            // }
            // localStorage.clear();
        },
        formError () {
            this.errorMessage = 'Something went wrong.. Please try again later';
        }
    }
};
</script>
